var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-section", {
    attrs: { status: _vm.sectionStatus, "is-loading": _vm.isLoadingSection },
    scopedSlots: _vm._u([
      {
        key: "header-right",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c("sun-date-picker-v2", {
                  key: _vm.datepickerKey,
                  ref: "datePicker",
                  attrs: {
                    id: "analytics-filter-date-range",
                    value: _vm.quickFiltersDate,
                    "max-date": _vm.limitDateRange.max,
                    "no-header": "",
                    "no-calendar-icon": "",
                    range: true,
                    "show-date-list": "",
                    "date-list": _vm.dateList,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onQuickFiltersDate($event.value)
                    },
                    "select:date-list-option": _vm.selectDateOption,
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass:
                  "flex flex-col items-end justify-end w-full p-2 rounded-t-md bg-white lg:flex-row lg:items-center",
              },
              [
                _c(
                  "sun-button",
                  {
                    staticClass:
                      "mr-4 px-3 custom-p-1 text-xs bg-gray-700 h-6 flex",
                    class: { "hover:bg-gray-800": _vm.hasFilters },
                    attrs: {
                      variant: "pill",
                      color: "orange",
                      disabled: !_vm.hasFilters,
                    },
                    on: { click: _vm.resetFilters },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "mr-1" },
                      [_c("trash-svg", { staticClass: "w-4 inline" })],
                      1
                    ),
                    _c("span", { staticClass: "whitespace-no-wrap" }, [
                      _vm._v(" Reset filters "),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "h-10 mb-2 breakdown lg:mb-0 flex items-center",
                  },
                  [
                    _c("breakdown-wrapper", {
                      attrs: {
                        label: "Select Breakdowns",
                        items: _vm.breakdownOptions,
                        "hide-secondary": "",
                        "horizontal-grow": "",
                        disabled: _vm.breakdownDisabled,
                      },
                      on: {
                        input: _vm.onBreakdownHandler,
                        reset: _vm.resetBreakdown,
                      },
                      model: {
                        value: _vm.breakdown,
                        callback: function ($$v) {
                          _vm.breakdown = $$v
                        },
                        expression: "breakdown",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "flex flex-row items-center mb-2 ml-5 lg:mb-0",
                  },
                  [
                    _c(
                      "asterix-dropdown",
                      {
                        attrs: {
                          "button-classes":
                            "flex hover:bg-gray-300 focus:bg-gray-800 text-gray-700 focus:text-gray-200 rounded h-10 px-4 items-center",
                          disabled: _vm.isLoading,
                          options: _vm.reportOptions,
                        },
                        on: { change: _vm.handlerReport },
                        scopedSlots: _vm._u([
                          {
                            key: "dropdown-header",
                            fn: function () {
                              return [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "p-2 pt-0 text-sm font-bold text-left whitespace-no-wrap",
                                  },
                                  [_vm._v("Download as")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "icon",
                            fn: function (state) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "transition-100",
                                    class: !state.visible ? "rotate-180" : "",
                                  },
                                  [
                                    _c("up-svg", {
                                      staticClass: "w-2 h-2",
                                      class: state.disabled
                                        ? "text-gray-400"
                                        : "text-gray-500",
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "block w-4 mr-2" },
                          [_c("download-svg")],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex flex-col w-full mb-8" },
              [
                _c("ag-grid-vue", {
                  key: "`Markets${tableKey}`",
                  staticClass: "ag-theme-alpine",
                  staticStyle: { height: "500px" },
                  attrs: {
                    pagination: "",
                    "animate-rows": "",
                    "row-group-panel-show": "",
                    "column-defs": _vm.tableColumnsDefinition,
                    "column-types": _vm.columnTypes,
                    "auto-group-column-def": _vm.autoGroupColumnDef,
                    "row-data": _vm.rowData,
                    "scrollbar-width": 10,
                    "suppress-agg-func-in-header": true,
                    "default-col-def": _vm.defaultColDef,
                    "group-display-type": "singleColumn",
                  },
                  on: {
                    "grid-ready": _vm.newGridReady,
                    "row-group-opened": _vm.rowGroupOpened,
                  },
                }),
              ],
              1
            ),
            _vm.isLoadingTotals
              ? _c("analytics-total-loader", {
                  attrs: { columns: _vm.totalsColumnNames },
                })
              : _vm.totals
              ? _c(
                  "box",
                  { staticClass: "mb-8" },
                  [
                    _vm._l(_vm.totalsColumn, function (col, $i) {
                      return _c("box-column", {
                        key: `box-column-${$i}`,
                        attrs: {
                          title: col.name,
                          value: _vm._f("valueToLocalString")(
                            _vm.totals[col.id]
                          ),
                        },
                      })
                    }),
                    _vm.currencySelected === 0
                      ? _c("box-column", {
                          attrs: {
                            title: "Net Revenue",
                            value: _vm._f("currencyEurFilter")(
                              _vm.totals["netRevenue"],
                              _vm.notAvailable,
                              "es-es"
                            ),
                          },
                        })
                      : _vm.currencySelected === 1
                      ? _c("box-column", {
                          attrs: {
                            title: "Net Revenue",
                            value: _vm._f("currencyUsdFilter")(
                              _vm.totals["netRevenueUsd"],
                              _vm.notAvailable,
                              "es-es"
                            ),
                          },
                        })
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }